import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import type { CartItem, Cart, FlatCart } from "./types";
import { cartReducer } from "./reducer";
import { useUser } from "../Auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  emptyCart,
  flattenCart,
  flatCartSchema,
  inflateCart,
  syncCartToDatabase,
  syncCartToLocalStorage,
  verifyCart,
} from "./util.client";

export type CartContext = {
  cart: Cart;
  addItemToCart: (item: CartItem) => void;
  deleteItemFromCart: (product: string) => void;
  cartIsEmpty: boolean | undefined;
  clearCart: () => void;
  getProductCartQuantity: (productID: string) => number;
  hasInitialized: boolean;
};

const Context = createContext({} as CartContext);

export const useCart = () => useContext(Context);

const arrayHasItems = (array: any) => Array.isArray(array) && array.length > 0;

// Step 1: Check local storage for a cart
// Step 2: If there is a cart, fetch the products and hydrate the cart
// Step 3a: If the user is authenticated, merge the user's cart with the local cart
// Step 3b: If the user is authenticated, Sync the cart to the database and clear local storage
// Step 4: If the user is logged out, sync the cart to local storage only
export const CartProvider: React.FC<{
  taxRate: number;
  children?: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [cart, dispatchCart] = useReducer(cartReducer, emptyCart);

  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      if (!hasInitialized) {
        // Step 1: Check local storage for a cart
        const parsed = flatCartSchema.safeParse(
          JSON.parse(window.localStorage.getItem("cart") || "null"),
        );

        // if we cannot parse the cart, we will treat it as non-existent
        const localCart: FlatCart | null = parsed.success ? parsed.data : null;

        // Step 2: If there is a cart, fetch the products and hydrate the cart
        if (localCart?.items?.length) {
          dispatchCart({
            type: "SET_CART",
            payload: await inflateCart(localCart),
          });
        }
        if (user) {
          // Step 3a: If the user is authenticated, merge the user's cart with the local cart
          // at this point we will filter out any items that are not in the database
          if (user.cart?.items?.length) {
            dispatchCart({
              type: "MERGE_CART",
              payload: await verifyCart(user.cart as Cart),
            });
          }
          // Step 3b: Sync the cart to the database and clear local storage
          if (cart?.items?.length) {
            localStorage.removeItem("cart");
            await syncCartToDatabase(flattenCart(cart));
          }
        } else {
          // Step 4: If the user is logged out, sync the cart to local storage only
          syncCartToLocalStorage(cart);
        }

        setHasInitialized(true);
      }
    })();
  }, [cart, hasInitialized, user]);

  // every time the cart changes, determine whether to save to local storage or Payload based on authentication status
  // upon logging in, merge and sync the existing local cart to Payload
  useEffect(() => {
    if (!hasInitialized) return;
    if (user?.id) {
      syncCartToDatabase(flattenCart(cart));
    } else {
      syncCartToLocalStorage(cart);
    }
  }, [cart, hasInitialized, user]);

  const getCartItemByProductID = useCallback(
    (productID: string): CartItem | undefined =>
      cart?.items?.find(
        (item) => item.product && item.product.id === productID,
      ),
    [cart],
  );

  const getProductCartQuantity = useCallback(
    (productID: string): number =>
      getCartItemByProductID(productID)?.quantity || 0,
    [getCartItemByProductID],
  );

  // this method can be used to add new items AND update existing ones
  const addItemToCart = useCallback(
    ({ product, quantity }: CartItem) => {
      dispatchCart({
        type: "ADD_ITEM",
        payload: {
          product,
          quantity,
        },
      });
      toast.success(t("cart.notification.added"));
    },
    [t],
  );

  const deleteItemFromCart = useCallback(
    (productID: string) => {
      dispatchCart({
        type: "DELETE_ITEM",
        productID,
      });
      toast.success(t("cart.notification.removed"));
    },
    [t],
  );

  const clearCart = useCallback(() => {
    dispatchCart({
      type: "CLEAR_CART",
    });
    toast.success(t("cart.notification.cleared"));
  }, [t]);

  return (
    <Context.Provider
      value={{
        cart,
        addItemToCart,
        deleteItemFromCart,
        cartIsEmpty: hasInitialized && !arrayHasItems(cart?.items),
        clearCart,
        getProductCartQuantity,
        hasInitialized,
      }}
    >
      {/* <pre className="max-h-[33vh] overflow-y-scroll">
        {JSON.stringify(cart, null, 2)}
      </pre> */}
      {children}
    </Context.Provider>
  );
};
