import type { Cart, FlatCart } from "./types";
import { z } from "zod";

export const emptyCart = {
  items: [],
};

export const flatCartSchema = z.object({
  items: z.array(z.object({ product: z.string(), quantity: z.number() })),
});

/**
 * Replace product objects in a cart object with their IDs
 */
export const flattenCart = (cart: Cart): FlatCart => ({
  ...cart,
  items: (cart.items ?? []).map(({ product, quantity }) => ({
    product: product.id,
    quantity,
  })),
});

/**
 * Get the product details for each item in the cart
 */
export const inflateCart = async (cart: FlatCart): Promise<Cart> => {
  if (cart.items.length === 0) return { items: [] };
  const res = await fetch("/api/cart/hydrate", {
    method: "POST",
    body: JSON.stringify(cart.items.map((item) => item.product)),
    headers: {
      "Content-Type": "application/json",
    },
  });
  let products: Record<string, any> = {};
  try {
    products = await res.json();
  } catch (e) {
    console.error("Error fetching products from the /api/cart/hydrate");
    console.error(e);
  }

  return {
    items: cart.items
      .filter((item) => item.product in products) // remove items that are not in the database
      .map((item) => ({
        product: products[item.product],
        quantity: item.quantity,
      })),
  };
};

export const verifyCart = async <T extends Cart | FlatCart>(
  cart: T,
): Promise<T> => {
  if (cart.items.length === 0) return cart;
  const res = await fetch("/api/cart/verify", {
    method: "POST",
    body: JSON.stringify(
      cart.items.map(({ product }) =>
        typeof product === "string" ? product : product.id,
      ),
    ),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const blacklist = await res.json();
  return {
    ...cart,
    items: cart.items.filter(
      ({ product }) =>
        !blacklist.includes(typeof product === "string" ? product : product.id),
    ),
  };
};

/**
 * call the route to update the cart in the database
 */
export const syncCartToDatabase = async (cart: FlatCart) => {
  await fetch("/api/cart/update", {
    method: "PATCH",
    body: JSON.stringify(cart.items),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * flatten the cart and save it in localStorage
 */
export const syncCartToLocalStorage = (cart: Cart) => {
  localStorage.setItem("cart", JSON.stringify(flattenCart(cart)));
};
