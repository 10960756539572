import type { Cart, CartItem } from "./types";

type Action =
  | {
      type: "SET_CART";
      payload: Cart;
    }
  | {
      type: "MERGE_CART";
      payload: Cart;
    }
  | {
      type: "ADD_ITEM";
      payload: CartItem;
    }
  | {
      type: "DELETE_ITEM";
      productID: string;
    }
  | {
      type: "CLEAR_CART";
    };

export const cartReducer = (cart: Cart, action: Action): Cart => {
  switch (action.type) {
    case "SET_CART": {
      return action.payload;
    }

    case "MERGE_CART": {
      const { payload: incomingCart } = action;

      const syncedItems: CartItem[] = [
        ...(cart?.items || []),
        ...(incomingCart?.items || []),
      ].reduce((acc: CartItem[], item) => {
        // remove duplicates
        const indexInAcc = acc.findIndex(
          ({ product }) => product.id === item.product.id,
        );

        if (indexInAcc > -1) {
          acc[indexInAcc] = {
            ...(acc[indexInAcc] as CartItem),
            quantity: Math.max(acc[indexInAcc].quantity, item.quantity),
          };
        } else {
          acc.push(item);
        }
        return acc;
      }, []);

      return {
        ...cart,
        items: syncedItems,
      };
    }

    case "ADD_ITEM": {
      // if the item is already in the cart, increase the quantity
      const { payload: incomingItem } = action;

      const indexInCart = cart?.items?.findIndex(
        ({ product }) => product.id === incomingItem.product.id,
      );

      const withAddedItem = [...(cart?.items || [])];

      if (indexInCart === -1) {
        withAddedItem.push(incomingItem);
      }

      if (typeof indexInCart === "number" && indexInCart > -1) {
        withAddedItem[indexInCart] = {
          ...(withAddedItem[indexInCart] as CartItem),
          quantity:
            (incomingItem.quantity || 0) > 0 ? incomingItem.quantity : 0,
        };
      }

      return {
        ...cart,
        items: withAddedItem,
      };
    }

    case "DELETE_ITEM": {
      const withDeletedItem = { ...cart };

      const indexInCart = cart?.items?.findIndex(
        ({ product }) => product.id === action.productID,
      );

      if (
        typeof indexInCart === "number" &&
        withDeletedItem.items &&
        indexInCart > -1
      )
        withDeletedItem.items.splice(indexInCart, 1);

      return withDeletedItem;
    }

    case "CLEAR_CART": {
      return {
        ...cart,
        items: [],
      };
    }

    default: {
      return cart;
    }
  }
};
